import { useFetch } from "@vgno/hooks";
import { Teaser } from "@vgno/teasers/Teaser";
import { classnames, fetcher } from "@vgno/utils";

import type { Article } from "@vgno/article";

import styles from "./AffiliateArticles.module.css";

const makeUrl = ({
  articleId,
  isFallback,
  teaserUrl,
}: {
  articleId: string;
  isFallback: boolean;
  teaserUrl: string;
}): string => {
  const url = new URL(teaserUrl);
  url.searchParams.set("utm_source", "vg-article");
  url.searchParams.set("utm_medium", "AffiliateArticles");
  url.searchParams.set("utm_campaign", isFallback ? "fallback" : "related");
  url.searchParams.set("utm_content", articleId);
  return url.toString();
};

interface Props {
  articleId: string;
}

type TeaserWithFallback = Article["teaser"] & { isFallback: boolean };

const AffiliateArticles = ({ articleId }: Props) => {
  const { data } = useFetch<TeaserWithFallback[]>(
    `/artikkel/api/affiliate/${articleId}`,
    fetcher,
  );

  return (
    <section className={styles.articles}>
      <h3
        className={classnames(
          styles.title,
          "font-inter font-weight-semi-bold title-large",
        )}
      >
        Kommersielt innhold
      </h3>

      {data?.map((teaser, index) => (
        <track-element
          data-track-primary-position={index + 1}
          data-track-secondary-position={index + 1}
          data-track-target-newsroom={teaser.newsroom}
          data-track-id={`teaser:${teaser.id}`}
          data-track-element-type={`${teaser.isFallback ? "Fallback" : "Related"} Affiliate Articles`}
          data-track-click-intent="View"
          data-track-target-type="Article"
          data-track-image-url={
            teaser.promotionContent?.imageAsset?.urls?.[0]?.url
          }
          data-track-impression
          data-track-name={teaser.title.value}
          data-track-no-utm
          key={teaser.id}
        >
          <Teaser
            alt={teaser.promotionContent?.alternateText?.value}
            authors={teaser.authors}
            contentType={teaser.contentType}
            fetchpriority="low"
            headlineType="h2"
            imageAsset={teaser.promotionContent.imageAsset}
            kicker={"Annonse"}
            newsroom={teaser.newsroom}
            paywall={Boolean(teaser?.customProperties?.paywall) ?? false}
            size={"small"}
            timestamp={teaser.changes.published}
            title={teaser.title.value}
            type={teaser.customProperties.presentation}
            url={makeUrl({
              articleId,
              isFallback: teaser.isFallback,
              teaserUrl: teaser.links.canonicalUrl,
            })}
          />
        </track-element>
      ))}
    </section>
  );
};

export default AffiliateArticles;
